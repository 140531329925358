<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('CourseReport') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters
        :default-status="2"
        @onSendDate="addPayload"
      />
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.serve_code`]="{ item }">
          <router-link
            v-if="item.serve_code"
            :to="{ name: 'showDetailService', params: { id: item.serve_id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.serve_code }}
          </router-link>
        </template>
        <template v-slot:[`item.course_total`]="{ item }">
          <span
            class="font-weight-medium text-decoration-none primary--text"
            style="cursor:pointer"
            @click="getCourseByStatus(item.customer_id_pri, 2)"
          >
            {{ item.course_total }}
          </span>
        </template>
        <template v-slot:[`item.course_wait`]="{ item }">
          <span
            class="font-weight-medium text-decoration-none primary--text"
            style="cursor:pointer"
            @click="getCourseByStatus(item.customer_id_pri, 1)"
          >
            {{ item.course_wait }}
          </span>
        </template>
        <template v-slot:[`item.course_use`]="{ item }">
          <span
            class="font-weight-medium text-decoration-none primary--text"
            style="cursor:pointer"
            @click="getCourseByStatus(item.customer_id_pri, 3)"
          >
            {{ item.course_use }}
          </span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="1000"
    >
      <v-card :loading="detailLoading">
        <v-card-title>
          {{ $t("check_course_services") }} <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="dialog = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table
            v-if="statusGetCourse == '2'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                  <th class="text-left">
                    {{ $t("customer_patient") }}
                  </th>
                  <th class="text-left">
                    {{ $t("doctor") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_number") }}
                  </th>
                  <th class="text-left">
                    {{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_date") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.course_name }}</td>
                  <td>{{ item.order_id }}</td>
                  <td>
                    {{ item.customer_fullname ? item.customer_fullname : "-" }}
                  </td>
                  <td>{{ item.user_fullname ? item.user_fullname : "-" }}</td>
                  <td>{{ item.serve_code ? item.serve_code : "-" }}</td>
                  <td>{{ item.count }}</td>
                  <td>
                    <ConverseDate :date="item.serving_date" />
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    {{ $t("total") }}
                  </td>
                  <td>{{ totalCount }}</td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="statusGetCourse == '1'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-center">
                    {{ $t("amount") }}/{{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.course_name }}

                    <ol class="mb-1">
                      <li
                        v-for="i,index in item.drug_list"
                        :key="index"
                      >
                        <DrugStatus :type="i.drug_category_id" />
                        {{ i.drug_name }} ({{ i.drug_unit }}) {{ $t('balance') }} : {{ i.servingdetail_book_amount }}
                      </li>
                    </ol>
                  </td>
                  <td class="text-center">
                    {{ item.count }}
                  </td>
                  <td>{{ item.order_id }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ $t("total") }}
                  </td>
                  <td class="text-center">
                    {{ totalCount }}
                  </td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="statusGetCourse == '3'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("course_name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_code") }}
                  </th>
                  <th class="text-left">
                    {{ $t("customer_patient") }}
                  </th>
                  <th class="text-left">
                    {{ $t("doctor") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_number") }}
                  </th>
                  <th class="text-left">
                    {{ $t("times") }}
                  </th>
                  <th class="text-left">
                    {{ $t("service_date") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataCourseList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.course_name }}</td>
                  <td>{{ item.order_id }}</td>
                  <td>
                    {{ item.customer_fullname ? item.customer_fullname : "-" }}
                  </td>
                  <td>{{ item.user_fullname ? item.user_fullname : "-" }}</td>
                  <td>{{ item.serve_code ? item.serve_code : "-" }}</td>
                  <td>{{ item.count }}</td>
                  <td>
                    <ConverseDate :date="item.serving_date" />
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    {{ $t("total") }}
                  </td>
                  <td>{{ totalCount }}</td>
                  <td>{{ $t("times") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiHumanMale, mdiHumanFemale, mdiClose, mdiFileExcelOutline,
} from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import { courseReport, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'
import service from '@/api/service/service'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'

export default {
  components: {
    DateFilters,
    ConverseDate,
    Comeback,
    DrugStatus,
  },
  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 110 },
      {
        text: i18n.t('firstname_lastname'), value: 'customer_fullname', width: 200,
      },
      { text: i18n.t('gender'), value: 'customer_gender', width: 80 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      { text: i18n.t('add_on'), value: 'customer_create', width: 140 },
      { text: i18n.t('status'), value: 'customer_status_name', width: 100 },
      {
        text: i18n.t('all_courses'), value: 'course_total', width: 180, align: 'center',
      },
      {
        text: i18n.t('waiting_service'), value: 'course_wait', width: 150, align: 'center',
      },
      {
        text: i18n.t('use_service'), value: 'course_use', width: 150, align: 'center',
      },
      { text: i18n.t('last_used'), value: 'use_date', width: 150 },
    ])
    const options = ref({})
    const exportLoading = ref(false)

    const statusGetCourse = ref('')
    const dialog = ref(false)
    const totalCount = ref(0)
    const dataCourseList = ref([])
    const detailLoading = ref(false)

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await courseReport(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const getCourseByStatus = (id, status) => {
      dataCourseList.value = []
      dialog.value = true
      detailLoading.value = true
      statusGetCourse.value = status
      totalCount.value = 0
      service.serviceDetailList({
        customer_id_pri: id,
        serving_status_id: status,
        lang: i18n.locale,
      }).then(res => {
        for (const item of res) {
          const checkCourse = dataCourseList.value.findIndex(subItem => (
            subItem.serve_id == item.serve_id
              && subItem.course_id_pri == item.course_id_pri
              && subItem.order_id == item.order_id
          ))
          if (checkCourse == -1) {
            item.count = 1
            dataCourseList.value.push(item)
          } else {
            dataCourseList.value[checkCourse].count += 1
          }
          totalCount.value++
        }
        detailLoading.value = false
      })
    }

    const mapData = data => {
      data = data.map(item => {
        delete item.customer_id_pri

        return item
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const dataExport1 = mapData(dataTableList.value)
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport1)))
      const fileName = `${i18n.t('CourseReport')}${i18n.t(
        'detail_at_day',
      )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [
          `${i18n.t('CourseReport')} ${i18n.t(
            'detail_at_day',
          )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
            'to',
          )} ${sumdate(end)}  `,
        ],
        [
          '#',
          `${i18n.t('customer_code')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('add_on')}`,
          `${i18n.t('status')}`,
          `${i18n.t('all_courses')}`,
          `${i18n.t('waiting_service')}`,
          `${i18n.t('use_service')}`,
          `${i18n.t('last_used')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'customer_id',
          'customer_fullname',
          'customer_gender',
          'customer_tel',
          'customer_create',
          'customer_status_name',
          'course_total',
          'course_wait',
          'course_use',
          'use_date',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols = [
        { wch: 6 },
        { wch: 8 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 12 },
        { wch: 15 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
      ]

      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('report_service_inspection')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      XLSX,
      payload,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,

      statusGetCourse,
      dialog,
      totalCount,
      dataCourseList,
      detailLoading,
      exportExcel,
      getCourseByStatus,
      addPayload,
      mdiFileExcelOutline,
      icons: {
        mdiHumanMale, mdiHumanFemale, mdiClose,
      },
    }
  },
}
</script>
